<template>
<div>
    <div  @click="moveToCandidateList" class="card tw-p-3 d-flex flex-column justify-space-between">
      <div>
        <h3 class="text-capitalize name mb-2 ">{{ eachTalentPool.name }}</h3>
        <h4 class="createdBy text-capitalize">Created by {{ getFirstNameAndInitial(eachTalentPool.created_by) }}</h4>
      </div>
      <div class="d-flex mb-1 align-center justify-space-between">
<!--        Candidates Avatar-->
        <div v-if="eachTalentPool.candidates.length === 0">
          <h4 class="noCandidate more">No candidates</h4>
        </div>
        <div v-else class=" d-flex align-center ml-2">
          <div
              v-for="(candidate, index) in eachTalentPool.candidates"
              :key="index"
          >
            <template v-if="index < 3 || view">
              <v-avatar class="avatar ml-n2" size="25">
                <h4 class="text-capitalize">{{ getInitials(candidate.name) }}</h4></v-avatar>
            </template>
          </div>
          <h5 class="more">
            {{ numOfApplicant(eachTalentPool.candidates.length) }}
          </h5>
        </div>

        <h4 class="date">{{ processedDate(eachTalentPool.date_created )}}</h4>
      </div>

    </div>
</div>
</template>

<script>
// import {mapMutations} from "vuex";

export default {
  name: "TalentPoolCard",
  data(){
    return{
      view: false
    }
  },
  props:{
    eachTalentPool:Object
  },
  computed:{
    getInitials() {
      return name => name.substring(0, 1);
    },
  },
  methods:{
    // ...mapMutations("talentPool", ["getTalentPoolNameAndDate"]),
    moveToCandidateList() {
      let nameAndDate ={
        name: this.eachTalentPool.name,
        date_created: this.eachTalentPool.date_created,
        description: this.eachTalentPool.description
      }
      console.log(nameAndDate)
      sessionStorage.setItem("poolNameAndDate", JSON.stringify(nameAndDate));
      // this.getTalentPoolNameAndDate(data)
      this.$router.push({name: 'AllCandidateInATalentPool',params:{talentPoolId: this.eachTalentPool.id}})
    },

    getFirstNameAndInitial(name){
      let firstName = name.split(" ")[0]
      let lastName = name.split(" ")[1].substring(0, 1);
       return firstName + " " + lastName
    },
    processedDate(gottenDate){
      let dateAndTime = gottenDate.split("T");

      let date = dateAndTime[0];
      let newDate = new Date(date).toDateString();

      let month = newDate.split(" ")[1]
      let day = newDate.split(" ")[2]
      let year = newDate.split(" ")[3]

      return day + " "+ month + ", " + year

    },
    numOfApplicant( num_of_applicant){
      if (num_of_applicant <= 3){
        return ""
      }else {
        return "+" + (num_of_applicant - 3) + " more"
      }
    },
  },

}
</script>

<style scoped>
.card{
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.08);
  border-radius: 8px;
  transition: box-shadow .3s;
  cursor: pointer;
  height: 180px;
}
h3,h4, h5{
  font-family: DM Sans;
  font-style: normal;
}
.card:hover{
  box-shadow: 2px 8px 20px 1px rgba(0, 142, 239, 0.12);
}
h3{
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #1E323F;
}
.createdBy{
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.avatar{
  background: #EEF8FF;
  border: 1px solid #FFFFFF;
}
.avatar h4{
  color: #008EEF;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}
.more ,.date{
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #475661;
}
.name{
  word-break: break-word;
}
.noCandidate{
  color: #9CABB5;
}
</style>