<template>
<div style="margin-top: 35px" class=" ml-1">
  <div class="tw-py-6 tw-px-4 md:tw-p-10">
    <div class="d-flex justify-space-between align-center mb-10">
      <h3 class="pr-3">Talent Pool</h3>
      <div  v-if="all_talentPool.length !== 0">
        <CreateTalentPoolModal>
          <template #button>
            <base-button width="130" id="create" button-text="Create">
              <template #left-icon>
                <PlusIcon size="20" class="custom-class"/>
              </template>
            </base-button>
          </template>
        </CreateTalentPoolModal>
      </div>

    </div>

    <div class="mb-3">
      <v-row>
        <v-col sm="12" cols="12" md="3" class=" tw-order-1 md:tw-order-none ">
          <div  v-if="all_talentPool.length !== 0" >
            <v-text-field class="" v-model="search"  hide-details label="Search" solo/>
          </div>
        </v-col>
      </v-row>
    </div>
    <div>
      <Loader v-if="dataLoading"/>
      <div v-else>
        <div v-if="all_talentPool.length === 0">
          <div class=" applyFlexCenter tw-w-full tw-flex-col tw-h-60 my-3">
            <h3 class="noTalentPool mb-5">You haven’t created a Talent Pool</h3>
            <CreateTalentPoolModal>
              <template #button>
                <base-button width="130" id="create2" button-text="Create">
                  <template #left-icon>
                    <PlusIcon size="20" class="custom-class"/>
                  </template>
                </base-button>
              </template>
            </CreateTalentPoolModal>
          </div>
        </div>

        <v-row  v-if="returnedFilteredSearch.length > 0 && search !== '' ">
          <v-col
              cols="12"
              md="4"
              sm="6"
              v-for="(eachTalentPool, index) in filteredResult"
              :key="index">
            <TalentPoolCard :each-talent-pool="eachTalentPool"/>
          </v-col>
        </v-row>
        <div  v-if="returnedFilteredSearch.length === 0 && search !== '' ">
          <EmptyStateComponent>
            <h4>No result matches your search</h4>
          </EmptyStateComponent>
        </div>

        <v-row v-if="all_talentPool.length > 0 && search === ''">
          <v-col
              cols="12"
              md="4"
              sm="6"
              v-for="(eachTalentPool, index) in all_talentPool"
              :key="index">
            <TalentPoolCard :each-talent-pool="eachTalentPool"/>
          </v-col>
        </v-row>
      </div>

    </div>


  </div>
</div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import TalentPoolCard from "@/components/talentPool/TalentPoolCard";
import CreateTalentPoolModal from "@/components/talentPool/CreateTalentPoolModal";
import {mapGetters} from "vuex";
import getSiteName from "@/services/utils";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import EmptyStateComponent from "@/components/UIComponents/EmptyStateComponent";
import {PlusIcon} from "vue-feather-icons";
// import {PlusIcon} from "vue-feather-icons"
export default {
name: "AllTalentPoolPage",
  components: {EmptyStateComponent,BaseButton,PlusIcon, Loader, CreateTalentPoolModal, TalentPoolCard},
  async created() {
    this.dataLoading = true
      await this.$store.dispatch('editCompany/getCompanyProfile', this.$siteName())
   let siteName = getSiteName()
    this.$store
        .dispatch("talentPool/getAllTalentPool", siteName)
        .then(res => {
          console.log(res);
          this.dataLoading = false
        })
        .catch(err => {
          console.log(err);
          this.dataLoading = false
        });
  },
  computed: {
    ...mapGetters("talentPool",["all_talentPool"]),
    returnedFilteredSearch(){
      return this.filteredResult
    }
  },
    data(){
  return{
    search:"",
    dataLoading:"",
    filteredResult:[]
  }
  },
  watch: {
    search: function(newValue, oldValue) {
      console.log(oldValue)
      let allTalentPool = [...this.all_talentPool]
      this.filteredResult = [...allTalentPool.filter(pool => (pool.name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1) || (pool.created_by.toLowerCase().indexOf(newValue.toLowerCase()) !== -1))]
      console.log(this.filteredResult)
    }
  },
}
</script>

<style scoped>
.mainCard{
  min-height: 73vh;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
  border-radius: 8px;
}

h3{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #142E70;
}
.noTalentPool{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: rgba(71, 86, 97, 1);
}
.custom-class{
  color: white;
}
</style>
